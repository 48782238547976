import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's been a couple months coming now, but I'm nearing version 1.0 of my
power strip! You may be asking a few questions now, like "what power strip?"
Well, after feeling frustrated about having to walk across the room to turn
off a lamp with an awkward off-switch, I decided to over-engineer a
solution.
Starting with a power strip I found on Amazon, I began by taking it apart
and looking at what I had to work with.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABzXsUc0xJA//EABsQAQEAAgMBAAAAAAAAAAAAAAIBERMAAwQS/9oACAEBAAEFAtiCnqU5vzGTjvEKMnz/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIDAAMAAAAAAAAAAAAAAAABESExAkFx/9oACAEBAAY/AuVtPqC4YnGmFIXh/8QAGxABAAICAwAAAAAAAAAAAAAAAQAxESFRYaH/2gAIAQEAAT8h34hwKQbTznQLNxwEueZVKUMpP//aAAwDAQACAAMAAAAQvz//xAAXEQEBAQEAAAAAAAAAAAAAAAARAAEh/9oACAEDAQE/EDjlgX//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QpYmU3//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVGhYf/aAAgBAQABPxBI50wMvdONfNw4B9hfyzyANRoOkMQDNLlUAvSwx4iiy+J//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "power strip opened up",
          "title": "power strip opened up",
          "src": "/static/27b90e68a5b81e9b7cb7750d9804716a/6aca1/power-strip-guts.jpg",
          "srcSet": ["/static/27b90e68a5b81e9b7cb7750d9804716a/d2f63/power-strip-guts.jpg 163w", "/static/27b90e68a5b81e9b7cb7750d9804716a/c989d/power-strip-guts.jpg 325w", "/static/27b90e68a5b81e9b7cb7750d9804716a/6aca1/power-strip-guts.jpg 650w", "/static/27b90e68a5b81e9b7cb7750d9804716a/7c09c/power-strip-guts.jpg 975w", "/static/27b90e68a5b81e9b7cb7750d9804716a/ddced/power-strip-guts.jpg 1100w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Fortunately, this strip happened to leave a lot of space to work with. I
began by disconnecting the physical relays. I had a series of 8
electronically controlled relays, which I wired the outlets to after making
sure I was properly handling mains-level current. With a few rudimentary
python scripts in place, I had a working prototype! It looked like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "177.91411042944785%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAkABQDASIAAhEBAxEB/8QAGgABAQACAwAAAAAAAAAAAAAAAAQDBQECBv/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGbvzjCrWprQ0xbGhtY035kWlQCv//EABwQAAICAwEBAAAAAAAAAAAAAAECAAMREiETIP/aAAgBAQABBQKs7TCk+USvRFALbCLkovZyV3lJXdV8C5xP/8QAFhEAAwAAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AZZ//8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEDH/2gAIAQIBAT8BHJlo/8QAHxAAAQMDBQAAAAAAAAAAAAAAEQABAhAhMRIgQVGB/9oACAEBAAY/AgtLOVheKPXNBEhk0YxzW1pbAV//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMVEQcUGx0f/aAAgBAQABPyG+A1l9YHf7L18IT3y2Zv1BtROtsdyawuxwlkB6P3ABQYcTGSzsjNAxXUvyPS/uf//aAAwDAQACAAMAAAAQtOxN/wDv/8QAGREBAQEAAwAAAAAAAAAAAAAAAREAEEFR/9oACAEDAQE/ECHWhiJNOBe7/8QAGhEBAAMAAwAAAAAAAAAAAAAAAQAQESFBUf/aAAgBAgEBPxBV7qV5ZNpHk//EACEQAQACAQMEAwAAAAAAAAAAAAEAESExQWFRcYGhkbHw/9oACAEBAAE/EB5WDQN2B2Isui9t0KVMVoI17iZuCVTwJELDQW1WUP2JfCko5FRXDwvhQ51nFgugLl9vidPK7DEUbBsA1yaP3zLxZrJkr028TEo0JRUIfSDSts//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "strip prototype",
          "title": "strip prototype",
          "src": "/static/87f4182a1826af32ffcc4deb6871205d/20e5d/strip-prototype.jpg",
          "srcSet": ["/static/87f4182a1826af32ffcc4deb6871205d/d2f63/strip-prototype.jpg 163w", "/static/87f4182a1826af32ffcc4deb6871205d/c989d/strip-prototype.jpg 325w", "/static/87f4182a1826af32ffcc4deb6871205d/20e5d/strip-prototype.jpg 450w"],
          "sizes": "(max-width: 450px) 100vw, 450px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Yes, the rubber band was necessary`}</p>
    </blockquote>
    <p>{`With that in place, I created a rudimentary Node server with a web view and
used the strip that way for about a month. The server knew how to call ten
python scripts, which would set the state of 5 GPIO pins to control the
outlets. My webpage was incredibly minimalistic, and I only wrote buttons
for the two outlets I was regularly using: a lamp and a space heater.{" "}`}</p>
    <p>{`It wasn't until June that I picked the project back up for some major
revisions. Since I knew that needed to present a final project for
Galvanize, I decided to take everything to the next level. I wanted a more
intelligent server and view, and a much tidier and more compact piece of
hardware. For the server, I decided to continue using Node.js with Express,
and I decided that React.js had a philosophy of state that would suit this
project particularly well.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "411px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "94.47852760736197%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADuUlEQVQ4y1WU12/bVhTG+acYRYuijqdkTZISp0iJpCROUcOyZXnIlgccOw6SIOh4auqXPnSn6UMHupA+9B/8inM04j5cXOKOH7/vnHuOsFqsYK2sYkM2sFW1kFHryOoOdkwPuVoLeav9v0FrtJfVXWTUBt/ZkE2siRpWi1UIS5hiIaM1kDObKFhtFOoBio0QJSdCyYl5LrsxSo2Q9whOYLqzpdgzaFmFsCHNlc1h25qDbd1DzvZRbEQoOjGPkpsgZwezYfnImi1kzSZ2zOYMSkolA8JmpYZttb6EDS9ucXjzHHZ3jFr3AHbvEO5wAjM9wPT5ZxhcPEF7dIpgPIUa9JchIPvEEkguxaxgt7Glu3j56kt8/tUPeP3b3/j+17/w4+9v8fPbfzF6/AKvvnmDN3/8g/tvf8J3v/yJL75+zW7oLsV0W7EhkDr6Q8H2OU5aNIQaDREcXaI9vkB4co3O2S307iF/RyfXsPpH2L24Q3JyxaGhmC7iKWQ0h2UX6yHKXgKx1UUl3IUcDqEkI6jpGFp6iNpgAi0dQ4lHqMb7ENs9lLwOxGaHk0cMciqQVMoYqRObKSrBAEq8B6NLkBPYwzPU989h7035m8C0pyb7qAYDvkN3iZE1XAg7hst283aA+PgKx3cfI57cwOyfwBxM4E9ucfHpPc4/uUd8/pTXzP4xBlfPML59ifDoEkU3ZsaO4RFwFr9yM4IcJRDbEcuX/T6s4SmMwQjG7giy32N7pLAa7vIZpRGg7gQ8PwDOFIqhD3UvQqZmYuXRJtZKKoz+GHK3BTFp4oNsHiurmwyj57Hy0QaUkox+rQGv5qC4AJJvjqEXQWzHEFsxB7nc6kKjWKX70HsjBkntHpTOGJI/4DNVJ4LiRJAbIfIMdCkpDnJWiw+IXoqKP4DWOUB6fof+1TMEx49hDaew9qYIJ7cYXr9AcnoDozuGHAxQbqYoLpKiu/Rs5vVbD7hWpVYXSjSEf3jJ787eO4PeO4LRO4I7mjLMG01RiYZ8tuwmKNYD5GqzEhTodS9ss1Uvwbqo4b2tAvSKgY5Zh9NOuZ7fzxSxlZcQ6jb8mgPZS5bqKH5UJMKiMVCRF+YNYUPS8WFOgqXbCC0XVjNhB7SWlzRec2ouRIJRs+AG4XCDEKjtbM/rmWTTxZIbs1K52UGllXIy6BnRLDVTSO6sneXZamtZx8QSyN6i49AGQdl+PWQ71LbKyzEDFeZZpbMMU+vMWBd1CI9KCtYlfQadK33YrQvz4ufBFfWwazvvYJIOYv0HaCXMWUCJqGsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "strip app",
          "title": "strip app",
          "src": "/static/0b60c1d91b5ae972ce4c3cd92ec3bac2/2a432/strip-app.png",
          "srcSet": ["/static/0b60c1d91b5ae972ce4c3cd92ec3bac2/222b7/strip-app.png 163w", "/static/0b60c1d91b5ae972ce4c3cd92ec3bac2/ff46a/strip-app.png 325w", "/static/0b60c1d91b5ae972ce4c3cd92ec3bac2/2a432/strip-app.png 411w"],
          "sizes": "(max-width: 411px) 100vw, 411px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The first version of my new view`}</p>
    </blockquote>
    <p>{`Using a switch animation I found on codePen (credited on the site and on
github), I rebuilt the code in React and made it modular, creating one of
the chief pieces of my view. Simple is better for an interface like this,
and I wanted a UI that I would enjoy using from this point on. The next step
was to take the power strip apart and do it better.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGZ69aWc0BP/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAwQhMf/aAAgBAQABBQIWmLpYBCbVydIRYvn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAhASITEygv/aAAgBAQAGPwJxGjikyh+RpnVW/8QAHBABAAIBBQAAAAAAAAAAAAAAAQARIUFRYZHB/9oACAEBAAE/IcgaRTDvYTY6bIA4IF5I98IQoFHE/9oADAMBAAIAAwAAABDP/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QNSwv/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxDIWf/EABkQAQEAAwEAAAAAAAAAAAAAAAERACExQf/aAAgBAQABPxCA4zB09m95X36rRxrO1CKjPfcPngAMbmyvq85Fonghn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A new hope",
          "title": "A new hope",
          "src": "/static/9a66c7a1d186bd0f1b27fcfc51e1e842/6aca1/gutted-strip.jpg",
          "srcSet": ["/static/9a66c7a1d186bd0f1b27fcfc51e1e842/d2f63/gutted-strip.jpg 163w", "/static/9a66c7a1d186bd0f1b27fcfc51e1e842/c989d/gutted-strip.jpg 325w", "/static/9a66c7a1d186bd0f1b27fcfc51e1e842/6aca1/gutted-strip.jpg 650w", "/static/9a66c7a1d186bd0f1b27fcfc51e1e842/7c09c/gutted-strip.jpg 975w", "/static/9a66c7a1d186bd0f1b27fcfc51e1e842/ddced/gutted-strip.jpg 1100w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`A new hope`}</p>
    </blockquote>
    <p>{`This time, I removed all of the physical switches, and carved out the
support structures that surrounded them. I would need all the space I could
get inside the strip for my next step.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABn6SM2gP/xAAbEAACAwADAAAAAAAAAAAAAAABAgADEhETQf/aAAgBAQABBQIO07W019gPvEwJ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAQQCAwAAAAAAAAAAAAAAAAECETIQQTNRcf/aAAgBAQAGPwK7kWezkdHpdTZvH//EAB4QAAIBAwUAAAAAAAAAAAAAAAABESFhkTFBUXGh/9oACAEBAAE/IY7tjgUqvmGVSXNznI7ZMl1Xp//aAAwDAQACAAMAAAAQH+//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EKlr/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhYf/aAAgBAgEBPxBPCH//xAAcEAEAAgIDAQAAAAAAAAAAAAABABExQSGR0fD/2gAIAQEAAT8QtdQq0dInwPoeSm9HhI2RHIld/ZSxyfWYWlVdq9n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The new relays",
          "title": "The new relays",
          "src": "/static/a7bf0ae0a7ba51398e2fcd2f1ced5cd7/08871/strip-with-relays.jpg",
          "srcSet": ["/static/a7bf0ae0a7ba51398e2fcd2f1ced5cd7/d2f63/strip-with-relays.jpg 163w", "/static/a7bf0ae0a7ba51398e2fcd2f1ced5cd7/c989d/strip-with-relays.jpg 325w", "/static/a7bf0ae0a7ba51398e2fcd2f1ced5cd7/08871/strip-with-relays.jpg 432w"],
          "sizes": "(max-width: 432px) 100vw, 432px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The new relays`}</p>
    </blockquote>
    <p>{`With these relays off eBay, I was effectively able to split the larger block
I had used the first time, and now the relays could control their respective
outlets from inside the power strip. Space was tight, but I was committed to
fitting everything within the original housing.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "418px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABXOqksSwP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAwQR/9oACAEBAAEFAlpmPVMqatraTHXA5w//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRIZExMmH/2gAIAQEABj8CeUvKOY6FKSVmUjqtFI//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyFY6Q4WU5MyAGjiLWp9IjcwDSB0T//aAAwDAQACAAMAAAAQhy//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQACAwADAAAAAAAAAAAAAAEAIRExQVGBwf/aAAgBAQABPxAIkIFgL8Jpkbrnx2MduUKUuoqQtorArfpCwXoME//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "I make it look easy, right?",
          "title": "I make it look easy, right?",
          "src": "/static/bbdb27a955648de9d04b7e73a0734342/14429/strip-wiring.jpg",
          "srcSet": ["/static/bbdb27a955648de9d04b7e73a0734342/d2f63/strip-wiring.jpg 163w", "/static/bbdb27a955648de9d04b7e73a0734342/c989d/strip-wiring.jpg 325w", "/static/bbdb27a955648de9d04b7e73a0734342/14429/strip-wiring.jpg 418w"],
          "sizes": "(max-width: 418px) 100vw, 418px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`I make it look easy, right?`}</p>
    </blockquote>
    <p>{`The next steps required a live wire that would connect to each of the
relays, and for each relay to have another wire completing the circuit with
the socket. These relays require a 5v vcc input, and default to being closed
unless they also receive a signal on their input pins. So, by default, the
relays break the circuit for each outlet unless they are told to connect it
again.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "192px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "177.91411042944785%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAkABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAUB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHxluwmsPMq7kHOJtGrIlRFf//EABwQAAICAgMAAAAAAAAAAAAAAAEDAhEAEgQgIf/aAAgBAQABBQJrdWNxgJL42/kGlx9E5C7FKkdKGajK6f/EABYRAAMAAAAAAAAAAAAAAAAAABASIP/aAAgBAwEBPwELH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BX//EABsQAAIDAAMAAAAAAAAAAAAAAAABAhAxICFh/9oACAEBAAY/AooTR0qiloj0dYZx/8QAHBABAAMBAQADAAAAAAAAAAAAAQARIUFhEDFR/9oACAEBAAE/IT9u2Jus83owa0ZE8GthLcu1Fa8lhrxDcW+TsMX+xH8Uocj8/wD/2gAMAwEAAgADAAAAEN/tcg/f/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARAP/aAAgBAwEBPxBRgII//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEg/9oACAECAQE/EFXH/8QAIBABAAICAQQDAAAAAAAAAAAAAQARITFREEFhsZGh4f/aAAgBAQABPxBvC1Z4EQ9wAKYZohv1K6BNhzMdFXIY+YQtwK7GT7lihaGCoMFhLz3m9btWptkzhXwRm0PNT8BA1ADwdCxcz//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The new brains",
          "title": "The new brains",
          "src": "/static/bcafc7de21f85704441f99243a77922b/7809d/pi-zero.jpg",
          "srcSet": ["/static/bcafc7de21f85704441f99243a77922b/d2f63/pi-zero.jpg 163w", "/static/bcafc7de21f85704441f99243a77922b/7809d/pi-zero.jpg 192w"],
          "sizes": "(max-width: 192px) 100vw, 192px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The new brains`}</p>
    </blockquote>
    <p>{`While I had originally been using a Raspberry Pi 1B, I got a notification
that there were a few Raspberry Pi Zeroes in stock on Adafruit. I picked one
up for \\$5 and was thrilled to downsize my controller for the project. In the
picture above, you'll see that I manually soldered the GPIO pins on
backwards for this device. It forced me to do some mental gymnastics for
identifying pins, but it was necessary for how the pi would fit on the
completed product.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAV+uGuWkWkf/xAAaEAADAAMBAAAAAAAAAAAAAAABAgMABBIR/9oACAEBAAEFAmbyuoe6dQwTQuiKp2FAt//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAMAAgMAAAAAAAAAAAAAAAABEQIhIjEz/9oACAEBAAY/AsqtDxmpT1OjioOH/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIUExUXH/2gAIAQEAAT8hEOA1UJHsNkW0Ips8w9T4lcq3P//aAAwDAQACAAMAAAAQpN//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QFtv/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8Qcgv/xAAbEAEAAwEBAQEAAAAAAAAAAAABABExQSGBwf/aAAgBAQABPxCpmkbzv3YYIKAaYTpz3G46LxV4/ksDY1jItWiAtJbAuuz/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "\"Creative\" use of a soldering iron",
          "title": "\"Creative\" use of a soldering iron",
          "src": "/static/84a71da0665b9e09923bbaa7572695ae/6aca1/glory-shot.jpg",
          "srcSet": ["/static/84a71da0665b9e09923bbaa7572695ae/d2f63/glory-shot.jpg 163w", "/static/84a71da0665b9e09923bbaa7572695ae/c989d/glory-shot.jpg 325w", "/static/84a71da0665b9e09923bbaa7572695ae/6aca1/glory-shot.jpg 650w", "/static/84a71da0665b9e09923bbaa7572695ae/7c09c/glory-shot.jpg 975w", "/static/84a71da0665b9e09923bbaa7572695ae/ddced/glory-shot.jpg 1100w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`"Creative" use of a soldering iron`}</p>
    </blockquote>
    <p>{`After creating an opening here on the side of the case, I had a space for
the GPIO pins to point directly inside of the casing. Here, the Pi's ports
would be available and on display in case I ever needed to further
configuration. This way, I wouldn't need to open up the device constantly if
something went wrong.`}</p>
    <div {...{
      "className": "gallery"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABhvxPmvEB/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIDERITEP/aAAgBAQABBQLnyePMoYpbDTE15//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFtf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEv/aAAgBAgEBPwFmP//EAB0QAAIBBAMAAAAAAAAAAAAAAAABEQIDIkEQMmH/2gAIAQEABj8CyMlDR1VXpcnTRC3x/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAECFRYf/aAAgBAQABPyGjoeqhm09V6cWgLFRnwmY0go4Ot//aAAwDAQACAAMAAAAQX/8A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhYf/aAAgBAwEBPxDR5Mf/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EJUKOH//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAxIUFR/9oACAEBAAE/ELWfB3zhcwHhqPwaYDCTyjuvWN/olMlRAB6YCA3/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "showing the GPIO pins",
          "title": "showing the GPIO pins",
          "src": "/static/0a39c4686ec17ae6fcad40fb7dc002ad/6aca1/backward-pins.jpg",
          "srcSet": ["/static/0a39c4686ec17ae6fcad40fb7dc002ad/d2f63/backward-pins.jpg 163w", "/static/0a39c4686ec17ae6fcad40fb7dc002ad/c989d/backward-pins.jpg 325w", "/static/0a39c4686ec17ae6fcad40fb7dc002ad/6aca1/backward-pins.jpg 650w", "/static/0a39c4686ec17ae6fcad40fb7dc002ad/7c09c/backward-pins.jpg 975w", "/static/0a39c4686ec17ae6fcad40fb7dc002ad/ddced/backward-pins.jpg 1100w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
  `}<p parentName="div">{`Connecting the backwards GPIO pins`}</p>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "418px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABXOqksSwP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAwQR/9oACAEBAAEFAlpmPVMqatraTHXA5w//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRIZExMmH/2gAIAQEABj8CeUvKOY6FKSVmUjqtFI//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPyFY6Q4WU5MyAGjiLWp9IjcwDSB0T//aAAwDAQACAAMAAAAQhy//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABwQAQACAwADAAAAAAAAAAAAAAEAIRExQVGBwf/aAAgBAQABPxAIkIFgL8Jpkbrnx2MduUKUuoqQtorArfpCwXoME//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Wiring up the relays",
          "title": "Wiring up the relays",
          "src": "/static/bbdb27a955648de9d04b7e73a0734342/14429/strip-wiring.jpg",
          "srcSet": ["/static/bbdb27a955648de9d04b7e73a0734342/d2f63/strip-wiring.jpg 163w", "/static/bbdb27a955648de9d04b7e73a0734342/c989d/strip-wiring.jpg 325w", "/static/bbdb27a955648de9d04b7e73a0734342/14429/strip-wiring.jpg 418w"],
          "sizes": "(max-width: 418px) 100vw, 418px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
  `}<p parentName="div">{`Wiring up the relays`}</p>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.44171779141104%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMGBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAcqKZedT52w//8QAGhABAAIDAQAAAAAAAAAAAAAAAgABAxARE//aAAgBAQABBQJBmK+z1dw1ynjD1//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAdEAABAwUBAAAAAAAAAAAAAAAAAQISEBEhIjEy/9oACAEBAAY/AuOsnCUjyYNmotP/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMVFhgaH/2gAIAQEAAT8hZXa1Vkt00fPUrFt8hAMjWW7qPM//2gAMAwEAAgADAAAAECjP/8QAFxEAAwEAAAAAAAAAAAAAAAAAABEhYf/aAAgBAwEBPxBUWn//xAAXEQEAAwAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EIKY/8QAGxABAQEAAwEBAAAAAAAAAAAAAREAITFRYZH/2gAIAQEAAT8Q7O9JwnCzx9zUK4AMgwcKhKC/ZMeSEva5yEciF/cQg4DoN//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "pi attached to the switch",
          "title": "pi attached to the switch",
          "src": "/static/b5b8d85a54653ec73e0059d62bd4043a/6aca1/tidying.jpg",
          "srcSet": ["/static/b5b8d85a54653ec73e0059d62bd4043a/d2f63/tidying.jpg 163w", "/static/b5b8d85a54653ec73e0059d62bd4043a/c989d/tidying.jpg 325w", "/static/b5b8d85a54653ec73e0059d62bd4043a/6aca1/tidying.jpg 650w", "/static/b5b8d85a54653ec73e0059d62bd4043a/7c09c/tidying.jpg 975w", "/static/b5b8d85a54653ec73e0059d62bd4043a/ddced/tidying.jpg 1100w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
  `}<p parentName="div">{`
    You can see Pi Zero bolted onto the back with spacers
  `}</p>
    </div>
    <p>{`After selecting and wiring up the pins, I faced a problem. The relays
weren't switching on. With dozens of potential points of failure, I was
overwhelmed and gave up for the rest of the day. I'm sure all you hackers
out there know the feeling.`}</p>
    <p>{`The next day, after doing some research, I took a voltmeter to the pi and
identified two points where I could directly have access to the full current
of the usb input. It turned out that the pi was limiting the current as it
passed through the circuit board, and not enough current was making it to
the relays.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px",
          "marginBottom": "1rem"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "177.91411042944785%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAkABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIDBAUB/8QAFwEBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABqpYi56U2CWhFpvTHCFkAUBf/xAAfEAABAwMFAAAAAAAAAAAAAAACAAMEEBIhARMiMjP/2gAIAQEAAQUCIdsW+SsuUzzh9rVKyUXDqJsXELYBTSv/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AV//xAAVEQEBAAAAAAAAAAAAAAAAAAAgQf/aAAgBAgEBPwGL/8QAHRAAAgICAwEAAAAAAAAAAAAAAAECERAiIDFhgf/aAAgBAQAGPwK3Q3FWXEivSS8xBPo+Y2RrFLj/AP/EAB0QAQACAQUBAAAAAAAAAAAAAAEAETEhQVFhgRD/2gAIAQEAAT8h2MYxMoLvaX0CnUFciPfgAUFEJyB5DS7KPhQXVNQQeIhcUuM//9oADAMBAAIAAwAAABCf+g2ED//EABcRAQEBAQAAAAAAAAAAAAAAABEAISD/2gAIAQMBAT8QdIjeP//EABkRAAMAAwAAAAAAAAAAAAAAAAAQEQEhUf/aAAgBAgEBPxDopdRYX//EACAQAQACAgIBBQAAAAAAAAAAAAEAESExQVFhgZGhseH/2gAIAQEAAT8QArhQChfGIA2TBECXq9wtjrSopTdZlQcr7H7CKCwWnw0/cPCDAGAgi6XezpihV6qrMTMJQXZxGEaUolhj5iQVRNz/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The final product",
          "title": "The final product",
          "src": "/static/8bfc9020e1f639c614b3cbf4fb4b6140/20e5d/strip-head-on.jpg",
          "srcSet": ["/static/8bfc9020e1f639c614b3cbf4fb4b6140/d2f63/strip-head-on.jpg 163w", "/static/8bfc9020e1f639c614b3cbf4fb4b6140/c989d/strip-head-on.jpg 325w", "/static/8bfc9020e1f639c614b3cbf4fb4b6140/20e5d/strip-head-on.jpg 450w"],
          "sizes": "(max-width: 450px) 100vw, 450px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`At long last, I was able to close the back of the strip, only to realize
that I had wanted a couple cables to be directed inside the pi. The power
cable was supposed to run through the inside, and the wifi dongle was meant
to be housed inside the case. After cramming the cables through and
re-soldering a couple broken connections, the cables all fit inside. With
some minimal configuration to the server to update the GPIO pins, everything
was working.`}</p>
    <p>{`Since then, I've been working to improve the interface. I've added
functionality for scheduling events, and I'm working on scheduling repeating
events currently. My next steps will be to create a view to view, modify, or
cancel events, and to host an external website so that the strip will not
require special router configuration to be controlled from a different
network connection`}</p>
    <p>{`Thanks for checking this out! I'd love to answer any comments or questions
you might have about this project.`}</p>
    <p>{`You can check out my code at `}<a parentName="p" {...{
        "href": "github.com/krpeacock/power_strip"
      }}>{`github.com/krpeacock/power_strip`}</a>{`.`}</p>
    <p>
  {" "}
  If you are interested in getting in touch with me, send me a message at&nbsp;
  <a href="mailto@kai@peacock.dev">kai@peacock.dev</a>!
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      